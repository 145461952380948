
import { ListOpions } from "@/models/type"
import { defineComponent, ref, toRefs, watch } from "@vue/runtime-core"

export default defineComponent({
  name: 'dropdown',
  props: {
    statusList: {
      type: Array,
      default: (): Array<ListOpions> => []
    },
    activeBool: {
      type: Boolean,
      dafault: false
    }
  },
  setup(props, {emit}) {
    const activeName = ref('更多')

    const { statusList, activeBool } = toRefs(props)

    function command(value: number): void {
      const arr = statusList.value as ListOpions[]
      activeName.value = arr.find(item => item.value === value)?.name as string
      emit('command', value)
    }

    watch(activeBool, (val) => {
      if(!val) {
        activeName.value = '更多'
      }
    })

    return {
      activeName,
      command
    }
  }
})

