import api from "@/api";
import { ListOpions } from "@/models/type";
import { Options, Vue } from "vue-class-component";
import './index.scss'
import dropdown from './components/dropdown.vue'

@Options({
  components: { dropdown }
})
export default class MineIntegral extends Vue {

  public searchObj: any = {
    keyword: ''
  }
  public typeName: string | number = 'all'
  public activeBool = false

  public categoryTreeList: Array<ListOpions> = []

  public total = 0
  public pageNum = 1
  public pageSize = 12

  public tableData: Array<any> = []

  getData(): void {
    Promise.all([
      api.categoryTreeList()
    ]).then(res => {
      // 商品类型列表
      const defaultArr = [{ name: '全部', value: 'all' }]
      this.categoryTreeList = defaultArr.concat(res[0].data.map((item: any) => ({ name: item.name, value: item.id })))
    })
  }

  headerDom(): JSX.Element {
    const slot = {
      suffix: () => <div
        class="el-icon-search pointer"
        style={{
          padding: '0 10px'
        }}
        onClick={this.getList}
      />
    }
    return <div class="integral-header">
      <el-row gutter={60}>
        <el-col xs={24} sm={24} md={24} lg={12} xl={12}>
          <el-input
            class="integral-header-cell"
            v-model={this.searchObj.keyword}
            placeholder="请输入关键词搜索"
            v-slots={ slot }
          />
        </el-col>
        <el-col xs={24} sm={24} md={24} lg={12} xl={12}>
          <div class="integral-header-cell integral-header-type">
            <div class="cell-shop" onClick={this.toMayShop}>
              <i class="el-icon-shopping-cart-2" />
              <span>我的购物车</span>
            </div>
            <div>
              <div class="cell-type" >
                {this.categoryTreeList.slice(0, 3).map(
                  item => <div
                    class={['cell-type-show', this.typeName === item.value ? 'cell-type-active' : '']}
                    onClick={ () => this.typeChange(item.value) }
                  >
                    {item.name}
                  </div>
                )}
                <dropdown
                  statusList={this.categoryTreeList.slice(3)}
                  onCommand={ this.onCommand }
                  activeBool={this.activeBool}
                />
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  }

  /**
   * @description 点击下拉框返回的数据
   * @author Jinx
   * @date 2021-10-25 15:29:47
   * @param {(string | number)} value
   * @memberof integral
   */
  onCommand(value: string | number, e: Event): void {
    if(e) {
      this.activeBool = true
      this.typeName = value
      this.getList()
    }
  }

  toMayShop(): void {
    // 我的购物车
    this.$router.push({
      name: 'ShopCard'
    })
  }

  typeChange(value: string | number): void {
    this.activeBool = false
    this.typeName = value
    this.getList()
  }

  getList(): void {
    const productCategoryId = this.typeName === 'all' ? '' : this.typeName
    api.productSearch({
      pageNum: this.pageNum,
      pageSize: this.pageSize,
      productCategoryId,
      ...this.searchObj,
      type: 1
    }).then(res => {
      this.tableData = res.data.list
      this.pageNum = res.data.pageNum
      this.pageSize = res.data.pageSize
      this.total = res.data.total
    })
  }

  listDom(): JSX.Element {
    return <el-row  gutter={60}>
      {this.tableData.map(item => <el-col xs={24} sm={12} md={8} lg={8} xl={6}>
        <div class="integral-product"
          onClick={() => this.goDetail(item.id)}
        >
          <img src={item.coverImg} alt={item.name} />
          <div class="integral-product-right">
            <div class="integral-product-title">{item.name}</div>
            <div class="integral-product-bottom">
              <div>积分： <span>{item.originalPrice}</span></div>
              <div class="btn">
                <span>立即兑换</span>
                <i class="el-icon-arrow-right"/>
              </div>
            </div>
          </div>
        </div>
      </el-col>)}
    </el-row>
  }

  goDetail(id: number): void {
    if(document.body.clientWidth < 900) {
      // TODO 跳转的mobile
      window.location.href = `http://mobile.taosseract.com/pages/mall/detail?id=${id}&type=1`
    } else {
      this.$router.push({
        name: 'SanchuangDetail',
        params: {
          id
        },
        query: {
          type: 1
        }
      })
    }
  }

  handleCurrentChange(pageNum: number): void {
    this.pageNum = pageNum
    this.getList()
  }

  created(): void {
    this.getData()
  }

  render(): JSX.Element {
    return <div class="integral">
        {this.headerDom()}
        {this.listDom()}
        <el-pagination
          class="integral-pagination"
          onCurrentChange={this.handleCurrentChange}
          currentPage={this.pageNum}
          pageSize={this.pageSize}
          layout="prev, pager, next, total"
          total={ this.total }
        />
      </div>
  }

  mounted(): void {
    this.getList()
  }
}
